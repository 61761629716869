import React from "react";
import styled from "styled-components";
import { InterWhite20px } from "../styledMixins";

function ShortReviews() {
  return (
    <Reviews1>
      <Group4944>
        <Group4939 src="/img/group-4940-1@2x.png" alt="Group 4939" />
        <Paragraph>
          “better than most programming courses” - <strong>Erin</strong>
        </Paragraph>
      </Group4944>
      <Group4942>
        <Group4939 src="/img/group-4939-1@2x.png" alt="Group 4940" />
        <Paragraph1>
          "got the job and they&#39;re starting me on Monday” -{" "}
          <strong>Martin</strong>
        </Paragraph1>
      </Group4942>
      <Group4943>
        <Group4939 src="/img/group-4939-1@2x.png" alt="Group 4941" />
        <Paragraph2>
          “a hidden gem in web development learning" - <strong>Dan</strong>
        </Paragraph2>
      </Group4943>
    </Reviews1>
  );
}

const Reviews1 = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 87px;
  row-gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Group4944 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Group4939 = styled.img`
  width: 134px;
  height: 21px;
`;

const Paragraph = styled.p`
  ${InterWhite20px}
  margin-right: 2px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  max-width: 320px;
`;

const Group4942 = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  align-items: center;
  gap: 8px;
`;

const Paragraph1 = styled.p`
  ${InterWhite20px}
  margin-right: 2px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  max-width: 320px;
`;

const Group4943 = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  align-items: center;
  gap: 8px;
`;

const Paragraph2 = styled.p`
  ${InterWhite20px}
  margin-right: 2px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  max-width: 320px;
`;

export default ShortReviews;
