import styled from "styled-components";
import { OptimizedImage } from "../OptimizedImage";
import { BREAKPOINT_TABLET, ScrollbarStyles } from "../styledMixins";

const images = [
  "rejection-01_Ayk2IME7FP.png",
  "rejection-02_z_FIntwTp.png",
  "rejection-03_N-h7sjOsm.png",
  "rejection-04_G-Nvkoty7.png",
  "rejection-05_9kxAn-mE8.png",
  "rejection-06_P0z_c-PsX.png",
  "rejection-07_BIA7_35E_.png",
];

export function Rejections() {
  return (
    <Images>
      <ImagesAbsoluteWrapper>
        <ImagesScrollWrapper>
          {images.map((image, index) => (
            <Image3752 key={index} src={image} />
          ))}
        </ImagesScrollWrapper>
      </ImagesAbsoluteWrapper>
    </Images>
  );
}

const Images = styled.div`
  height: 308px;
  margin-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 28px;

  @media (${BREAKPOINT_TABLET}) {
    height: 408px;
  }
`;

const ImagesAbsoluteWrapper = styled.div`
  ${ScrollbarStyles}
  position: absolute;
  left: 0;
  height: 308px;
  width: 100%;
  overflow-x: scroll;

  @media (${BREAKPOINT_TABLET}) {
    height: 408px;
  }
`;

const ImagesScrollWrapper = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`;

const Image3752 = styled(OptimizedImage)`
  height: 100%;
  max-width: 85%;
  object-fit: contain;
`;
