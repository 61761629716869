import styled from "styled-components";
import { FaqItem } from "./FaqItem";

const data = [
  {
    question:
      "I'm not sure if my skills are enough. What are the prerequisites?",
    answer:
      "The tech stack (React, Next.js, SCSS modules, react-query, TypeScript, Cypress, Storybook) can sound overwhelming, but you don't need to have worked with all of this before.\n\nThe tasks are designed in a way that slowly exposes you to these technologies.\n\nThat said, this is not a program for beginners. You need some experience with React.\n\nIf you’ve built a simple application on your own, you should be at a good level to take advantage of this program.\n\nMy advice would be to join, try the exercises for 7 days and if you find it too difficult, you can always claim your money back.",
  },
  {
    question: "How does the 7-Day Money Back Guarantee work?",
    answer:
      "After you enroll in the React Job Simulator, you can have a look at the content, check out the project, and get your hands dirty on the first tasks.\n\nIf you decide for any reason that this is not for you let me know and I'll refund your money.",
  },
  {
    question: "How does this get me closer to a job?",
    answer:
      "Once you get an interview you should have a much easier time in the technical assessment. You'll have a lot of skills that are not common for Junior developers. You can show off your experience working on an advanced React project and know more options for approaching common problems.\n\nThis will set you apart from many other entry-level candidates.\n\nOnce you land a job the journey isn't over. The challenge is to perform in a way that lets you keep the job. Many companies throw their Junior developers into the deep end. You're expected to swim on your own. Having been through this process with the React Job Simulator will make it much easier and likely that you get through this time.",
  },
  {
    question: "What kind of material is included in the program?",
    answer:
      "During early access, you get a personal repository on GitHub, designs on Figma, and a Kanban board with the tasks. Additionally, there is some introductory material to skills like working with designs, creating pixel-perfect layouts, or writing tests with Cypress. But don't expect in-depth introductory material to all technologies used in the project. You won't get a lot of hand-holding on the job and you won't get it here. Instead expect to conduct the research yourself and get comfortable reading documentation. This is a far more valuable skill that can be used on any job. That's why the core of this course is the tasks. Most students spend at least 95% of their time actively working on the code base.\n\nFinally, you will also get access to a private community on Discord.",
  },
  {
    question: "What is the Job-Readiness Assessment?",
    answer:
      "For the Job-Readiness Assessment I'll look at your code and (if required) at your resume. I'll also ask a series of question about the current state of your job search. Depending on the outcome I'll give advice on how to develop your skills further and improve your chances of landing a job.\n\nNote that you need to finish at least ~50% of the tasks (currently at least 10 out of 21) to provide a meaningful evaluation. The more tasks you finish the better the assessment.",
  },
  {
    question: "How much time do I need to invest?",
    answer:
      "This highly depends on your current skill level so it's hard to give a clear answer. To finish all tasks expect at least 3-4 weeks if you work on it a couple of hours per day consistently.",
  },
  {
    question: "Why is this a cohort-based program? And what does that mean?",
    answer:
      "Cohort here simply means that a group of people starts the program at the same time. Every developer gets their own code repository, implements the tasks on their own, and works on their own schedule. There's no deadline and you can’t “fall behind”.\n\n I've experimented with different formats in the past and I've found that starting as a group is by far the most enjoyable and effective way. The sense of community that builds during the program is just amazing. You'll find people asking questions, helping each other out, celebrating progress, and forming strong bonds. It's a vibe that I've yet to see replicated outside of a cohort-based approach.",
  },
  {
    question: "Can I use this project on my resume/portfolio?",
    answer: "Yes, you can certainly put this project on your resume.",
  },
  {
    question: "What is the price of this program?",
    answer: `At the moment there are three payment options:
      
- $99 which includes access to the project, task, and designs. You also get limited access to the AI mentor.
- $179 which additionally includes access to over-the-shoulder walkthrough videos for each task and unlimited access to the AI mentor.
- $249 which additionally includes a Job-Readiness Assessment (see the other FAQ item for more info on that). 

You'll receive more details about these options when the next cohort starts and you're on the waitlist.`,
  },
  {
    question: "Is this a one-time payment or a subscription?",
    answer:
      "You only need to pay once and get lifetime access to the program, all material, the community, and future updates. No monthly fees.",
  },
  {
    question: "Do you offer discounts?",
    answer:
      "I offer PPP discounts if you're from a country where the US Dollar is expensive, you're unemployed or a student. Reach out to discounts@profy.dev and we'll figure it out.",
  },
  {
    question: "My question has not been answered.",
    answer:
      "I understand, feel free to email me at johannes@profy.dev and I'll get back to you.",
  },
];

export function Faq() {
  return (
    <Questions1>
      {data.map((item, index) => (
        <FaqItem key={index} question={item.question} answer={item.answer} />
      ))}
    </Questions1>
  );
}

const Questions1 = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 20px;
`;
