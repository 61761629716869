import YouTube from "@components/video/youtube";
import styled from "styled-components";
import { OptimizedImage } from "../OptimizedImage";
import {
  BREAKPOINT_TABLET,
  InterMediumWhite16px,
  InterWhite16px,
  InterWhite18px,
  ScrollbarStyles,
  SpacegroteskBoldWhite22px,
  SpacegroteskBoldWhite55px,
  ValignTextMiddle,
} from "../styledMixins";

const images = [
  "review-1_vwA8aGs60.png",
  "review-2_9JwfYo_iX.png",
  "review-3_h1c4T0HcM9.png",
  "review-4_hsxNjye8t.png",
  "review-5_6y7HnUtGY.png",
];

type ReviewsProps = {
  title: string;
  id?: string;
};

function Reviews({ title, id }: ReviewsProps) {
  return (
    <X9Proof id={id}>
      <YourFirstReactJobStartsHere3>{title}</YourFirstReactJobStartsHere3>
      <VideoReviewWrapper>
        <YouTube
          id="M0rk3_JTiME"
          title="How Charles got his first dev job with the React Job Simulator"
        />
      </VideoReviewWrapper>
      <ScreenshotsWrapper>
        {images.map((image, index) => (
          <Image key={index} src={image} alt="Review" />
        ))}
      </ScreenshotsWrapper>
    </X9Proof>
  );
}

const X9Proof = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-margin-top: 100px;
`;

const YourFirstReactJobStartsHere3 = styled.p`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

const VideoReviewWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const ScreenshotsWrapper = styled.div`
  ${ScrollbarStyles}
  width: 100%;
  overflow-x: scroll;
  display: flex;
  gap: 20px;
  margin-top: 40px;

  @media (${BREAKPOINT_TABLET}) {
    height: 500px;
  }
`;

const Image = styled(OptimizedImage)`
  object-fit: contain;

  @media (${BREAKPOINT_TABLET}) {
    object-fit: unset;
  }
`;

// function Reviews() {
//   return (
//     <ReviewsWrapper>
//       <ReviewsAbsoluteWrapper>
//         <ReviewsScrollWrapper>
//           <Reviews1>
//             <TextAvatarStars22 spanText1="Best product I've ever used" />
//             <TextAvatarStars22
//               spanText1="Amazing"
//               className="text-avatar-stars-1"
//             />
//             <TextAvatarStars22
//               spanText1="Perfect for heavy sleepers"
//               className="text-avatar-stars-2"
//             />
//             <TextAvatarStars>
//               <Stars src="/img/stars-16@2x.png" alt="Stars" />
//               <TheyDeliveredOnTi>
//                 <span className="spacegrotesk-bold-white-21px">
//                   Perfect for heavy sleepers
//                 </span>
//               </TheyDeliveredOnTi>
//               <TheyDeliveredOnTi1>
//                 <span className="inter-normal-white-18px">
//                   They delivered on time, within our budget and went above and
//                   beyond to make the website a real success. Will absolutely and
//                   definitely work with them in the near future!
//                 </span>
//               </TheyDeliveredOnTi1>
//               <NathanJonhstonCEO>
//                 <span>
//                   <Span0>
//                     Nathan Jonhston
//                     <br />
//                   </Span0>
//                   <Span1>CEO at HyperLinks</Span1>
//                 </span>
//               </NathanJonhstonCEO>
//               <ReadMore>
//                 <span className="inter-medium-white-15px">Read more</span>
//               </ReadMore>
//             </TextAvatarStars>
//             <TextAvatarStars22 spanText1="Perfect for heavy sleepers" />
//           </Reviews1>
//         </ReviewsScrollWrapper>
//       </ReviewsAbsoluteWrapper>
//     </ReviewsWrapper>
//   );
// }

const ReviewsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 487px;
  margin-top: 40px;
`;

const ReviewsAbsoluteWrapper = styled.div`
  position: absolute;
  left: 0;
  height: 487px;
  width: 100%;
  overflow-x: scroll;
  padding: 0 20px;
`;

const ReviewsScrollWrapper = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`;

const Reviews1 = styled.div`
  height: 487px;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const TextAvatarStars = styled.div`
  width: 379px;
  display: flex;
  flex-direction: column;
  padding: 45px 0;
  align-items: center;
  min-height: 487px;
  background-color: var(--ebony-clay);
  border-radius: 10px;
  box-shadow: 50px 50px 100px #00000012;
`;

const Stars = styled.img`
  width: 122px;
  height: 18px;
  align-self: flex-start;
  margin-left: 45px;
`;

const TheyDeliveredOnTi = styled.div`
  ${SpacegroteskBoldWhite22px}
  width: 289px;
  margin-top: 23px;
`;

const TheyDeliveredOnTi1 = styled.p`
  ${InterWhite18px}
  width: 289px;
  min-height: 168px;
  margin-top: 24px;
`;

const NathanJonhstonCEO = styled.div`
  ${ValignTextMiddle}
  ${InterWhite16px}
  width: 200px;
  height: 65px;
  margin-top: 24px;
  margin-right: 89px;
  color: #7e7e7e;
`;

const Span0 = styled.span`
  color: var(--ebony-clay);
`;

const Span1 = styled.span`
  ${InterWhite16px}
  color: var(--ebony-clay);
`;

const ReadMore = styled.div`
  ${InterMediumWhite16px}
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 45px;
`;

export default Reviews;
