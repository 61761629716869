import React from "react";
import Frame1707478394 from "../Frame1707478394";
import styled from "styled-components";

function X1Row(props) {
  const { className, frame17074783941Props, frame17074783942Props } = props;

  return (
    <X1Row1 className={`x1-row ${className || ""}`}>
      <Frame1707478394
        materialSymbolsCheckCircleOutlineRo={
          frame17074783941Props.materialSymbolsCheckCircleOutlineRo
        }
        spanText1={frame17074783941Props.spanText1}
        spanText2={frame17074783941Props.spanText2}
        className={frame17074783941Props.className}
      />
      <Frame1707478394
        materialSymbolsCheckCircleOutlineRo={
          frame17074783942Props.materialSymbolsCheckCircleOutlineRo
        }
        spanText1={frame17074783942Props.spanText1}
        spanText2={frame17074783942Props.spanText2}
        className={frame17074783942Props.className}
      />
    </X1Row1>
  );
}

const X1Row1 = styled.div`
  position: relative;
  display: flex;
  gap: 29px;
  flex-wrap: wrap;
`;

export default X1Row;
