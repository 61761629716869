import YouTube from "@components/video/youtube";
import styled from "styled-components";
import {
  InterWhite14px,
  InterWhite20px,
  SpacegroteskBoldWhite32px,
  BREAKPOINT_TABLET,
  InterSemiBoldWhite14px,
} from "../styledMixins";

type BlockProps = {
  isOdd: boolean;
  videoId: string;
  title: string;
  description: string;
  highlights: string[];
  userName: string;
  review: string;
};

export function Block({
  isOdd,
  videoId,
  title,
  description,
  highlights,
  userName,
  review,
}: BlockProps) {
  return (
    <Wrapper isOdd={isOdd}>
      <VideoWrapper>
        <YouTube id={videoId} />
      </VideoWrapper>
      <Info>
        <Content>
          <Headline>{title}</Headline>
          <Description>{description}</Description>
        </Content>
        <CheckmarkItems>
          {highlights.map((highlight, index) => (
            <CheckmarkItem key={index}>
              <Checkmark src="/img/component-1-1@2x.png" alt="Component 1" />
              <CheckmarkItemText>{highlight}</CheckmarkItemText>
            </CheckmarkItem>
          ))}
        </CheckmarkItems>
      </Info>
      <Item1>
        <IReceivedGreatCus>
          <Raiting src="/img/raiting-3@2x.png" alt="Raiting" />
          {review}
        </IReceivedGreatCus>
        <User1 className="user">
          <Name className="name">{userName}</Name>
          <Item className="item-1">
            <Icon className="icon" src="/img/icon--3@2x.png" alt="Icon " />
            <VerifiedCustomer className="verified-customer">
              Verified member
            </VerifiedCustomer>
          </Item>
        </User1>
      </Item1>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isOdd: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "video"
    "content"
    "review";
  width: 100%;
  column-gap: 40px;
  row-gap: 20px;

  @media (${BREAKPOINT_TABLET}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ isOdd }) =>
      isOdd
        ? `"content video"
        "content review";
        `
        : `"video content"
        "review content";
        `};
  }
`;

const VideoWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
  grid-area: video;
`;

const Info = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  grid-area: content;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Headline = styled.p`
  ${SpacegroteskBoldWhite32px}
`;

const Description = styled.p`
  ${InterWhite20px}
  line-height: 30px;
`;

const CheckmarkItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const CheckmarkItem = styled.div`
  display: flex;
  gap: 17px;
`;

const Checkmark = styled.img`
  width: 35px;
  height: 35px;
`;

const CheckmarkItemText = styled.p`
  ${InterWhite20px}
  letter-spacing: 0;
  line-height: 30px;
`;

const Item1 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: review;
`;

const Raiting = styled.img`
  height: 12px;
  margin-right: 6px;
  margin-top: 4px;
  float: left;
`;

const IReceivedGreatCus = styled.p`
  ${InterWhite14px}
`;

const User1 = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Name = styled.div`
  ${InterSemiBoldWhite14px}
  letter-spacing: 0;
  white-space: nowrap;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`;

const VerifiedCustomer = styled.div`
  ${InterWhite14px}
  letter-spacing: 0;
  line-height: normal;
`;
