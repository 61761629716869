import React from "react";
import styled from "styled-components";
import { SpacegroteskMediumWhite36px } from "../styledMixins";

function Text22(props) {
  const { choice, spanText } = props;

  return (
    <Text>
      <Choice src={choice} alt="choice" />
      <SignUp>
        <span className="spacegrotesk-medium-white-35px">{spanText}</span>
      </SignUp>
    </Text>
  );
}

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
`;

const Choice = styled.img`
  width: 94px;
  height: 94px;
  margin-left: 0;
`;

const SignUp = styled.div`
  ${SpacegroteskMediumWhite36px}
`;

export default Text22;
