import styled from "styled-components";
import {
  BREAKPOINT_TABLET,
  InterMediumWhite16px,
  ScrollbarStyles,
} from "../styledMixins";

const data = [
  { text: "Learn basic React", profy: "no", bootcamps: "yes", courses: "yes" },
  { text: "1-1 Mentoring", profy: "no", bootcamps: "maybe", courses: "no" },
  {
    text: "Access to Senior developer",
    profy: "yes",
    bootcamps: "maybe",
    courses: "no",
  },
  { text: "Career center", profy: "no", bootcamps: "yes", courses: "no" },
  { text: "Price", profy: "$$", bootcamps: "$$$$$", courses: "$ - $$" },
  {
    text: "Professional Git workflow",
    profy: "yes",
    bootcamps: "maybe",
    courses: "no",
  },
  {
    text: "Work on production-grade codebase",
    profy: "yes",
    bootcamps: "maybe",
    courses: "no",
  },
  {
    text: "Real world experience",
    profy: "yes",
    bootcamps: "no",
    courses: "no",
  },
  {
    text: "Build features based on professional Figma designs",
    profy: "yes",
    bootcamps: "maybe",
    courses: "no",
  },
  {
    text: "Write automated tests",
    profy: "yes",
    bootcamps: "maybe",
    courses: "no",
  },
  {
    text: "Stand out during application process",
    profy: "yes",
    bootcamps: "no",
    courses: "no",
  },
  {
    text: "Confidence to start applying for jobs",
    profy: "yes",
    bootcamps: "no",
    courses: "no",
  },
  {
    text: "Increase chances of keeping your new job",
    profy: "yes",
    bootcamps: "no",
    courses: "no",
  },
  {
    text: "Money back guarantee",
    profy: "7 days",
    bootcamps: "maybe",
    courses: "maybe",
  },
  {
    text: "Job-readiness assessment",
    profy: "yes",
    bootcamps: "no",
    courses: "no",
  },
];

function getColumnContent(column: string) {
  switch (column) {
    case "yes":
      return <Vector src="/img/component-3-2@2x.png" alt="Component 1" />;
    case "maybe":
      return (
        <IconQuestionMark
          src="/img/vector-31@2x.png"
          alt="icon-question_mark"
        />
      );
    case "no":
      return <Vector src="/img/vector-30@2x.png" alt="Vector" />;
    default:
      return column;
  }
}

export function ComparisonTable() {
  return (
    <ScrollWrapper>
      <Table>
        <Header>
          <HeaderColumn></HeaderColumn>
          <HeaderColumn>Profy.dev</HeaderColumn>
          <HeaderColumn>Bootcamps</HeaderColumn>
          <HeaderColumn>Online Courses</HeaderColumn>
        </Header>
        {data.map((item, index) => (
          <Row key={index}>
            <Column>{item.text}</Column>
            <Column>{getColumnContent(item.profy)}</Column>
            <Column>{getColumnContent(item.bootcamps)}</Column>
            <Column>{getColumnContent(item.courses)}</Column>
          </Row>
        ))}
      </Table>
    </ScrollWrapper>
  );
}

const ScrollWrapper = styled.div`
  ${ScrollbarStyles}
  overflow-x: scroll;
  width: 100%;

  @media (${BREAKPOINT_TABLET}) {
    overflow-x: auto;
  }
`;

const Table = styled.div`
  width: 100%;
  display: table;
  padding: 0 14px;

  @media (${BREAKPOINT_TABLET}) {
    padding: 0;
  }
`;

const Header = styled.header`
  ${InterMediumWhite16px}
  background-color: var(--black-2);
  border-radius: 10px;
  display: table-row;
`;

const HeaderColumn = styled.div`
  display: table-cell;
  padding: 6px;

  @media (${BREAKPOINT_TABLET}) {
    padding: 20px;
  }
`;

const Row = styled.div`
  height: 76px;
  min-width: 100%;
  background-color: var(--black-2);
  border-radius: 10px;
  display: table-row;
`;

const Column = styled.div`
  ${InterMediumWhite16px}
  align-self: flex-start;
  display: table-cell;
  padding: 6px;
  vertical-align: middle;

  @media (${BREAKPOINT_TABLET}) {
    padding: 20px;
  }
`;

const Vector = styled.img`
  width: 27px;
  height: 27px;
`;

const IconQuestionMark = styled.img`
  width: 15px;
  height: 27px;
`;
