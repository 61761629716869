import React from "react";
import styled from "styled-components";
import {
  BREAKPOINT_TABLET,
  InterWhite20px,
  SpacegroteskBoldWhite32px,
} from "../styledMixins";

function Frame1707478394(props) {
  const {
    materialSymbolsCheckCircleOutlineRo,
    spanText1,
    spanText2,
    className,
  } = props;

  return (
    <Frame17074783941 className={`frame-1707478394 ${className || ""}`}>
      <MaterialSymbolscheckCircleOutlineRo
        className="material-symbolschec"
        src={materialSymbolsCheckCircleOutlineRo}
        alt="material-symbols:check-circle-outline-rounded"
      />
      <Text className="text">
        <FreshFood className="fresh-food">
          <span className="spantnkjb spacegrotesk-bold-white-32px">
            {spanText1}
          </span>
        </FreshFood>
        <Line413
          className="line-413"
          src="/img/line-413-1@2x.png"
          alt="Line 413"
        />
        <IfYoureAStudent className="if-youre-a-student">
          <span className="span2r46c inter-normal-white-20px">{spanText2}</span>
        </IfYoureAStudent>
      </Text>
    </Frame17074783941>
  );
}

const Frame17074783941 = styled.div`
  min-width: 320px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 20px;
  align-items: center;
  gap: 19px;
  background-color: var(--ebony-clay);
  border-radius: 10px;

  @media (${BREAKPOINT_TABLET}) {
    height: 100%;
  }
`;

const MaterialSymbolscheckCircleOutlineRo = styled.img`
  width: 80px;
  height: 80px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FreshFood = styled.div`
  ${SpacegroteskBoldWhite32px}
  text-align: center;
`;

const Line413 = styled.img`
  width: 65px;
  height: 1px;
  margin-top: 21px;
`;

const IfYoureAStudent = styled.p`
  ${InterWhite20px}
  margin-top: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 35px;
`;

export default Frame1707478394;
