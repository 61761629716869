type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const imageKitBase = "https://ik.imagekit.io/87wct6jq4ql/landing-page";
const imageWidths = [360, 480, 640, 1280];

const getOptimizedSrc = (src?: string) => {
  if (!src) return undefined;
  return `${imageKitBase}/${src}/tr:w-720`;
};

const getOptimizedSrcSet = (src?: string) => {
  if (!src) return undefined;
  return imageWidths
    .reduce(
      (prev, width) =>
        prev.concat(`${imageKitBase}/${src}/tr:w-${width} ${width}w`, ","),
      ""
    )
    .slice(0, -1);
};

export function OptimizedImage(props: ImageProps) {
  const src = getOptimizedSrc(props.src);
  const srcSet = getOptimizedSrcSet(props.src);
  return <img {...props} src={src} srcSet={srcSet} />;
}
