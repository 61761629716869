import React from "react";
import styled from "styled-components";

function Twitter() {
  return (
    <Twitter1 href="https://twitter.com/profydev" target="__blank">
      <Twitter2 src="/img/twitter-2@2x.png" alt="Twitter" />
    </Twitter1>
  );
}

const Twitter1 = styled.a`
  height: 40px;
  display: flex;
  padding: 14px 13px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 40px;
  background-color: #f6f5ff;
  border-radius: 20px;
`;

const Twitter2 = styled.img`
  width: 13px;
  height: 11px;
`;

export default Twitter;
