import React from "react";
import Text22 from "../Text22";
import styled from "styled-components";
import { SpacegroteskBoldBlack36px } from "../styledMixins";

function X1(props) {
  const { text22Props, number } = props;

  return (
    <X11>
      <OverlapGroup>
        <Number>{number}</Number>
      </OverlapGroup>
      <Text22 choice={text22Props.choice} spanText={text22Props.spanText} />
    </X11>
  );
}

const X11 = styled.div`
  width: 100%;
  max-width: 394px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 36px 20px;
  align-items: flex-start;
  gap: 60px;
  background-color: var(--ebony-clay);
`;

const OverlapGroup = styled.div`
  height: 117px;
  width: 117px;
  margin-top: -57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--coconut);
  border-radius: 50%;
`;

const Number = styled.div`
  ${SpacegroteskBoldBlack36px}
`;

export default X1;
