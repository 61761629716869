import { useAnalytics } from "@components/analytics";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { InterMediumWhite20px, InterWhite18px } from "../styledMixins";

type FaqItemProps = {
  question: string;
  answer: string;
};

export function FaqItem({ question, answer }: FaqItemProps) {
  const { trackEvent } = useAnalytics();

  return (
    <Wrapper>
      <Details>
        <Summary
          onClick={(e) => {
            const summaryElement = e.target as HTMLDetailsElement;
            const detailsElement =
              summaryElement.parentElement as HTMLDetailsElement;
            // only track when the details element is opened
            if (detailsElement?.open === false) {
              trackEvent("Click", "FAQ.Open", { question });
            }
          }}
        >
          {question}
          <Vector205 src="/img/vector-205-6@2x.png" alt="Vector 205" />
        </Summary>
        <Answer>
          <ReactMarkdown>{answer}</ReactMarkdown>
        </Answer>
      </Details>
    </Wrapper>
  );
}

const Wrapper = styled.li`
  width: 100%;
  max-width: 896px;
  background-color: var(--ebony-clay);
  border-radius: 10px;
  box-shadow: 0px 58px 70px #00000061;
`;

const Vector205 = styled.img`
  width: 17px;
  height: 8px;
  margin-left: 20px;
`;

const Details = styled.details`
  &[open] ${Vector205} {
    transform: rotate(180deg);
  }
`;

const Summary = styled.summary`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${InterMediumWhite20px}
  letter-spacing: 0;
  line-height: 28px;
  cursor: pointer;
  padding: 20px;
`;

const Answer = styled.div`
  ${InterWhite18px}
  padding-right: 40px;
  margin-left: 1.65px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0 20px 20px;

  & > ul {
    list-style: disc;
    padding-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
