import { css } from "styled-components";

export const BREAKPOINT_TABLET = "min-width: 768px";
export const BREAKPOINT_DESKTOP = "min-width: 1280px";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ScrollbarStyles = css`
  /* Foreground, Background */
  scrollbar-color: #999 #333;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    width: 10px; /* Mostly for vertical scrollbars */
    height: 10px; /* Mostly for horizontal scrollbars */
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    /* Foreground */
    background: #999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    /* Background */
    background: #333;
    border-radius: 5px;
  }
`;

const Font65px = css`
  font-size: 45px;
  line-height: 55px;

  @media (${BREAKPOINT_TABLET}) {
    font-size: 65px;
    line-height: 75px;
  }
`;

const Font55px = css`
  font-size: 35px;
  line-height: 45px;

  @media (${BREAKPOINT_TABLET}) {
    font-size: 55px;
    line-height: 65px;
  }
`;

const Font36px = css`
  font-size: 30px;
  line-height: 40px;

  @media (${BREAKPOINT_TABLET}) {
    font-size: 36px;
    line-height: 46px;
  }
`;

const Font32px = css`
  font-size: 26px;
  line-height: 34px;

  @media (${BREAKPOINT_TABLET}) {
    font-size: 32px;
    line-height: 42px;
  }
`;

const Font26px = css`
  font-size: 22px;
  line-height: 30px;

  @media (${BREAKPOINT_TABLET}) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const Font22px = css`
  font-size: 22px;
  line-height: 32px;
`;

const Font20px = css`
  font-size: 20px;
  line-height: 30px;
`;

const Font18px = css`
  font-size: 18px;
  line-height: 28px;
`;

const Font16px = css`
  font-size: 16px;
  line-height: 26px;
`;

const Font14px = css`
  font-size: 14px;
  line-height: 24px;
`;

export const InterBoldWhite22px = css`
  ${Font22px}
  font-family: var(--font-family-inter);
  color: var(--coconut);
  font-weight: 700;
  font-style: normal;
`;

export const InterWhite32px = css`
  ${Font32px}
  color: var(--coconut);
  font-family: var(--font-family-inter);
`;

export const InterWhite26px = css`
  ${Font26px}
  color: var(--coconut);
  font-family: var(--font-family-inter);
`;

export const InterWhite20px = css`
  ${Font20px}
  font-family: var(--font-family-inter);
  color: var(--coconut);
`;

export const InterMediumWhite20px = css`
  ${InterWhite20px}
  font-weight: 500;
  font-style: normal;
`;

export const InterSemiBoldWhite20px = css`
  ${Font20px}
  font-family: var(--font-family-inter);
  color: var(--coconut);
  font-weight: 600;
  font-style: normal;
`;

export const InterWhite18px = css`
  ${Font18px}
  font-family: var(--font-family-inter);
  color: var(--coconut);
`;

export const InterWhite16px = css`
  ${Font16px}
  font-family: var(--font-family-inter);
  color: var(--coconut);
`;

export const InterBoldWhite16px = css`
  ${InterWhite16px}
  font-weight: 700;
  font-style: normal;
`;

export const InterMediumWhite16px = css`
  ${InterWhite16px}
  font-weight: 500;
`;

export const InterSemiBoldWhite16px = css`
  ${InterWhite16px}
  font-weight: 600;
`;

export const InterWhite14px = css`
  ${Font14px}
  font-family: var(--font-family-inter);
  color: var(--coconut);
`;

export const InterMediumWhite14px = css`
  ${InterWhite14px}
  font-weight: 500;
`;

export const InterSemiBoldWhite14px = css`
  ${InterWhite14px}
  font-weight: 600;
`;

export const SpacegroteskBoldWhite65px = css`
  ${Font65px}
  color: var(--coconut);
  font-family: var(--font-family-space_grotesk);
  font-weight: 700;
  font-style: normal;
`;

export const SpacegroteskBoldWhite55px = css`
  ${Font55px}
  color: var(--coconut);
  font-family: var(--font-family-space_grotesk);
  font-weight: 700;
  font-style: normal;
`;

export const SpacegroteskMediumWhite36px = css`
  ${Font36px}
  color: var(--coconut);
  font-family: var(--font-family-space_grotesk);
  font-weight: 500;
  font-style: normal;
`;

export const SpacegroteskBoldBlack36px = css`
  ${Font36px}
  color: var(--licorice);
  font-family: var(--font-family-space_grotesk);
  font-weight: 700;
  font-style: normal;
`;

export const SpacegroteskWhite32px = css`
  ${Font32px}
  color: var(--coconut);
  font-family: var(--font-family-space_grotesk);
`;

export const SpacegroteskBoldWhite32px = css`
  ${SpacegroteskWhite32px}
  font-weight: 700;
  font-style: normal;
`;

export const SpacegroteskBoldWhite22px = css`
  ${Font22px}
  color: var(--coconut);
  font-family: var(--font-family-space_grotesk);
  font-weight: 700;
  font-style: normal;
`;

export const TextStyle = css`
  width: 100%;
  max-width: 905px;
  margin-left: auto;
  margin-right: auto;
`;
