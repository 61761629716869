import styled from "styled-components";
import { Block } from "./Block";

const data = [
  {
    videoId: "ruoYmtDClf8",
    title: "1. Learn a professional git workflow",
    description:
      "Git will be the foundation of your work and workflow as a professional developer. You’ll discover how to:",
    highlights: [
      "Confidently create, merge and review Pull Requests",
      "Work with Continuous Integration Pipelines",
    ],
    userName: "Dan",
    review:
      "“the Git and GitHub experience has been fantastic… Very much a good idea to give you a better idea of how branches and pull requests actually work.”",
  },
  {
    videoId: "W3569DHGG2Y",
    title: "2. Work on an existing codebase",
    description:
      "You’ll almost always work on an existing codebase, but navigating an unfamiliar codebase can be overwhelming. You’ll learn how to:",
    highlights: [
      "Navigate an unfamiliar codebase with ease",
      "Create bug fixes and UI changes",
    ],
    userName: "Alison Lee",
    review:
      "“I recently started a frontend apprenticeship working with React and many of the tasks I’m given are similar to this course. It allowed me to jump right in and start working on their codebase with more confidence.”",
  },
  {
    videoId: "_rs4oLwofmI",
    title: "3. Build a UI kit based on Figma designs",
    description:
      "Every front-end developer needs to know how to work with professional designs. You’ll learn how to:",
    highlights: [
      "Implement pixel-perfect designs with Figma",
      "Document components with Storybook",
    ],
    userName: "Martin",
    review:
      "“Got the job and they're starting me on Monday! The pixel-perfect design technique I learned here blew them away!”",
  },
  {
    videoId: "KWdcIzJNiK0",
    title: "4. Learn how to write automated tests",
    description:
      "Testing is a rare, but critical skill for Junior Developers. You’ll learn how to:",
    highlights: ["Write test with Cypress", "Debug your tests"],
    userName: "David",
    review:
      "“I never bothered with testing and fancy app design. Now I am looking for a new job and everyone wants TDD and proper project design.”",
  },
  {
    videoId: "ybeXar7qCI0",
    title: "5. Implement your own features",
    description:
      "Professional projects are often split into features, discussed, planned and prioritized. You’ll learn how to:",
    highlights: [
      "Work through feature requests from a Kanban board",
      "Practice everything by writing code based on designs and covering your work with automated tests",
    ],
    userName: "Ari",
    review:
      "“Discovering this program has been a fantastic experience…you learn how to structure a React project for scalability while implementing features based on high-quality Figma designs.”",
  },
  {
    videoId: "zUQ-3IhCuFM",
    title: "6. Community",
    description:
      "You’ll get access to a private Discord community where you’ll find support, help and inspiration from a Senior React developer and other members.",
    highlights: [
      "Access to a Senior developer with 10+ years of professional experience",
      "Connect with fellow students",
    ],
    userName: "Nukabunny",
    review:
      "“The Discord community does a great job helping with any problems I would struggle with.”",
  },
  {
    videoId: "9C18ruqc-Lg",
    title: "7. AI mentor bot",
    description:
      "Inside the Discord community you can also ask Tara (your AI mentor) questions about the codebase. Simply send her a DM and she'll help you out.",
    highlights: [
      "Work through feature requests from a Kanban board",
      "Practice everything by writing code based on designs and covering your work with automated tests",
    ],
    userName: "Leo",
    review:
      "“You and Tara (a ChatGPT Discord bot) have been very supportive in guiding me through the experience.”",
  },
];

export function Blocks() {
  return (
    <Wrapper>
      {data.map((item, index) => (
        <Block key={index} {...item} isOdd={Boolean(index % 2)} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 90px;
  padding: 20px;
`;
